<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-data-table
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Invoices") }}
                </h3>
              </v-col>
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    @keyup.enter="getAllData(page)"
                    v-model.trim="search"
                  />

                  <v-icon
                    :title="$t('Search')"
                    @click="getAllData(page)"
                    class="search-icon"
                    >search</v-icon
                  >
                </label>
              </v-col>

              <v-col
                md="4"
                sm="12"
                :class="currentAppLocale == 'en' ? 'text-right' : 'text-left'"
              >
                <span title="Filter">
                  <v-menu
                    v-model="filterMenu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        :title="$t('Search')"
                        large
                        color="#7297ff"
                        @click="initializeDatePicker"
                        >mdi-filter</v-icon
                      >
                    </template>

                    <v-card class="py-4 custom-filter">
                      <v-list>
                        <v-list-item class="select">
                          <div class="form-group">
                            <label class="custom-lable">{{ $t("Year") }}</label>
                            <v-autocomplete
                              v-model="filter.year_id"
                              :items="years"
                              @change="hideLabel = true"
                              item-text="name"
                              item-value="id"
                              multiple
                              solo
                            ></v-autocomplete>
                          </div>
                        </v-list-item>

                        <v-list-item>
                          <div class="form-group">
                            <label class="custom-lable">{{
                              $t("Issue Date From")
                            }}</label>
                            <v-text-field
                              id="from"
                              solo
                              v-model="filter.date_from"
                            ></v-text-field>
                          </div>
                        </v-list-item>
                        <v-list-item>
                          <div class="form-group">
                            <label class="custom-lable">{{
                              $t("Issue Date To")
                            }}</label>
                            <v-text-field
                              solo
                              id="to"
                              v-model="filter.date_to"
                            ></v-text-field>
                          </div>
                        </v-list-item>
                      </v-list>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="primary"
                          text
                          @click="filterMenu = false"
                          >{{ $t("Close") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </span>
              </v-col>
            </v-row>
          </template>
          <!-- <template v-slot:item.invoice_number="{ item }">
            <router-link :to="'/invoiceupdate/' + item.invoice_number">
              {{ item.invoice_number }}
            </router-link>
          </template> -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="main-color"
              @click="printInvoicePdf(item.invoice_number)"
              :title="$t('Print')"
              >print</v-icon
            >
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
          </v-snackbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
// import moment from "moment";
export default {
  name: "invoicesupdate",
  data() {
    return {
      options: {
        itemsPerPage: 15,
      },
      snack: "",
      types: [],
      dialogReset: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      //   loading: true,
      loading: false,
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Invoice ID"),
          value: "invoice_number",
        },
        {
          text: this.$i18n.t("Partner Code"),
          value: "partner_code",
        },
        {
          text: this.$i18n.t("Partner Name"),
          value: "partner_name",
        },
        // {
        //   text: this.$i18n.t("Grade"),
        //   value: "student_grade",
        //   sortable: false,
        // },
        {
          text: this.$i18n.t("Paid Amount"),
          value: "paid_amount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Issue Date"),
          value: "issue_date",
        },
        // {
        //   text: this.$i18n.t("Total"),
        //   value: "total",
        // },
        // {
        //   text: this.$i18n.t("Total After Discount"),
        //   value: "total_after_discount",
        // },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      title: this.$i18n.t("Invoices"),

      permissions: {
        list: ACL.checkPermission("list-invoices"),
      },
      years: [],
      items: [],
      filter: {
        year_id: [],
        date_from: "",
        date_to: "",
      },
      search: "",
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          //   this.getAllData(this.$route.query.page);
        } else {
          //   this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function () {
      this.getAllData();
    },

    // search: {
    //   handler() {
    //     this.page = 1;
    //     this.getAllData(this.page);
    //   },
    //   deep: true,
    // },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    initializeDatePicker() {
      let _this = this;

      setTimeout(() => {
        $("#from").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filter.date_from = dateH;
            } else {
              _this.filter.date_from = "";
            }
          },
        });

        $("#to").calendarsPicker({
          maxDate: 0,
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.filter.date_to = dateH;
            } else {
              _this.filter.date_to = "";
            }
          },
        });
      }, 500);
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },

    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/account-statement/invoices?search=" +
            this.search +
            "&page=" +
            page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    getYears() {
      axios
        .get(this.getApiUrl + "/school/academic_periods_all", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
          this.years.forEach((item) => {
            if (item.is_active == 1) {
              this.filter.year_id = [item.id];
            }
          });
        });
    },
    printInvoicePdf(invo) {
      axios
        .get(this.getApiUrl + "/account-statement/export-invoice/" + invo, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            window.open(response.data.data);
            // this.close();
          }
        });
    },
  },
  mounted() {
    this.getAllData(this.page);
    this.getYears();
  },
};
</script>

<style></style>
